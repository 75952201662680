import { v4 as uuidv4 } from 'uuid';
import { decodeJwt } from './util';

export const presentationStates = {
  LAUNCHED: 'OpenForRecord',
  RECORDING: 'Recording',
  RECORDED: 'Recorded',
};

const LOCAL_STORAGE_TOKEN = 'MediasiteST';

export const changePresentationState = async(url, sfApiKey, token, state) => {
  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        sfapikey: sfApiKey,
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Status: state,
      }),
    });
  } catch (error) {
    console.log(`changePresentationState encountered error: ${error.message}`);
  }
};

export const fetchMediasiteProperties = async(
  url,
  sfApiKey,
  token,
  presentationId,
  presentationUrl,
  settingsManagerObj
) => {
  var url1 = null;
  var url2 = null;
  var url3 = null;

  try {
    const response = await fetch(presentationUrl, {
      method: 'GET',
      headers: {
        sfapikey: sfApiKey,
        Authorization: `Bearer ${token}`,
      },
    });
    const responseJson =
      response.headers.get('content-type')?.indexOf('application/json') !== -1
        ? await response.json()
        : {};

    if (responseJson['#Upload'] === undefined ||
    responseJson['#Upload']?.target === undefined) {
      url1 = `${url}/FileServer/Presentation/${presentationId}`;
    } else {
      url1 = responseJson['#Upload'].target;
    }

    if (responseJson['#CreateMediaUploadUrl'] === undefined ||
    responseJson['#CreateMediaUploadUrl']?.target === undefined) {
      url2 = `${url}/Api/v1/Presentations('${presentationId}')/CreateMediaUpload`;
    } else {
      url2 = responseJson['#CreateMediaUploadUrl'].target;
    }

    if (responseJson['#CreatePendingFileDeletes'] === undefined ||
      responseJson['#CreatePendingFileDeletes']?.target === undefined) {
      url3 = `${url}/Api/v1/Presentations('${presentationId}')/CreatePendingFileDeletes`;
    } else {
      url3 = responseJson['#CreatePendingFileDeletes'].target;
    }
  } catch (error) {
    console.log(`fetchMediasiteProperties encountered error: ${error.message}`);
    settingsManagerObj.errorConditions.isMediasiteCommunicationSuccessful = false;
    settingsManagerObj.errorConditions.translate = true;

    if (
      error.message.includes('Failed to fetch') ||
      error.message.includes('NetworkError when attempting to fetch resource')
    ) {
      settingsManagerObj.errorConditions.errorMessage = 'cors-error-message';
    } else {
      settingsManagerObj.errorConditions.errorMessage = 'general-error-message';
    }
  }

  return [url1, url2, url3];
};

export const removeSessionToken = async() => {
  try {
    window.localStorage.removeItem(LOCAL_STORAGE_TOKEN);
  } catch (error) {
    console.log(`removeSessionToken encountered error: ${error.message}`);
  }
};

export const getSessionToken = async(url, sfApiKey, launchToken, presentationId,
  settingsManagerObj) => {
  var sessionToken;
  var isNewTokenNeeded = false;

  try {
    sessionToken = window.localStorage.getItem(LOCAL_STORAGE_TOKEN);
    isNewTokenNeeded = sessionToken === undefined || sessionToken === '' ||
    sessionToken === null;

    if(isNewTokenNeeded) {
      sessionToken = await exchangeLaunchToken(url, sfApiKey, launchToken);
    }

    isNewTokenNeeded = (await isTokenValid(url, sfApiKey, sessionToken, presentationId)) === false;

    if(isNewTokenNeeded) {
      sessionToken = await exchangeLaunchToken(url, sfApiKey, launchToken);
    }

    window.localStorage.setItem(LOCAL_STORAGE_TOKEN, sessionToken);
  } catch (error) {
    console.log(`getSessionToken encountered error: ${error.message}`);

    if (
      error.message.includes('Failed to fetch') ||
      error.message.includes('NetworkError when attempting to fetch resource')
    ) {
      settingsManagerObj.errorConditions.errorMessage = 'cors-error-message';
    } else {
      settingsManagerObj.errorConditions.errorMessage = 'general-error-message';
    }
  }

  return sessionToken;
};

const exchangeLaunchToken = async(url, sfApiKey, launchToken) => {
  try {
    const response = await fetch(`${url}/api/v1/Home/ExchangeLaunchToken`, {
      method: 'POST',
      body: JSON.stringify({
        LaunchToken: launchToken,
      }),
      headers: {
        sfapikey: sfApiKey,
        'Content-Type': 'application/json',
      },
    });

    const responseJson =
      response.headers.get('content-type')?.indexOf('application/json') !== -1
        ? await response.json() : {};

    return responseJson.SessionToken;
  } catch (error) {
    console.log(`exchangeLaunchToken encountered error: ${error.message}`);
    throw error;
  }
};

const isTokenValid = async(url, sfApiKey, sessionToken, presentationId) => {
  var testUrl = `${url}/Api/v1/Presentations('${presentationId}')`;

  try {
    const response = await fetch(testUrl, {
      method: 'GET',
      headers: {
        sfapikey: sfApiKey,
        Authorization: `Bearer ${sessionToken}`,
      },
    });

    return response.status !== 401;
  } catch (error) {
    console.log(`isTokenValid encountered an error: ${error}`);
    throw error;
  }

  return false;
};

export const generateNewRecordingName = deviceType => {
  const guid = uuidv4();
  const prefix = deviceType === 'desktop' ? 'display.webm' : 'camera.webm';
  return `${prefix}_${guid}.webm`;
};

export const getUpidFromToken = token => {
  var ret = decodeJwt(token);

  if(ret === null || ret.payload === null || ret.payload.upid === null) {
    return ret;
  }

  return ret.payload.upid;
};
